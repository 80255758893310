import React, { useEffect, useContext } from "react";

import PageLayout from "../components/layout/page-layout";
import { PageContext, pages } from "../context/page-context";

import style from "../styles/page-styles/404.module.scss";

const NotFoundPage = () => {
  const pageContext = useContext(PageContext);
  useEffect(() => {
    pageContext.setCurrentPage(pages.notFound);
  }, [pageContext.currentPage]);
  return (
    <PageLayout>
      <section className={style.pageContent}>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... </p>
      </section>
    </PageLayout>
  );
};

export default NotFoundPage;
